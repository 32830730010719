/* prettier-ignore */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* prettier-ignore */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
  line-height: 1;
}

/* prettier-ignore */
ol, ul {
    list-style: none;
}
blockquote,
q {
  quotes: none;
}

/* prettier-ignore */
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

div {
  box-sizing: border-box;
}

* {
  font-family: 'Noto Sans KR', sans-serif !important;
  -webkit-text-size-adjust: none;
}

sup {
  vertical-align: super;
  font-size: smaller;
  line-height: 0.5;
}

var,
span {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

var::-webkit-scrollbar,
span::-webkit-scrollbar {
  display: none;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: black !important;
}

html[color-mode='dark'] input:-webkit-autofill,
html[color-mode='dark'] input:-webkit-autofill:hover,
html[color-mode='dark'] input:-webkit-autofill:focus,
html[color-mode='dark'] input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: white !important;
  caret-color: white;
}
